import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import Navbar from './Navbar'
import Footer from './Footer'
// import Seo from '../Seo'

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      allSanityLearningMethod(sort: {fields: rank}) {
        nodes {
          title
          slug { current }
        }
      }
      allSanityStudyField(sort: {fields: title}) {
        nodes {
          title
          slug { current }
        }
      }
      sanityCompanyInfo {
        address
        email
        telephoneNumber
        whatsappNumber
        socialMediaLinks {
          facebook
          instagram
          youtube
        }
      }
      sanitySiteSettings {
        title
        quickLinks {
          title
          slug { current }
          url
        }
      }
    }
  `)

  const allLearningMethods = data.allSanityLearningMethod.nodes
  const allStudyFields = data.allSanityStudyField.nodes
  // const { socialMediaLinks, ...contactInfo } = data.sanityCompanyInfo
  let socialMediaLinks = null;
  let contactInfo = null;
  if (data.sanityCompanyInfo) {
    socialMediaLinks = data.sanityCompanyInfo.socialMediaLinks
    contactInfo = {
      address: data.sanityCompanyInfo.address,
      email: data.sanityCompanyInfo.email,
      telephoneNumber: data.sanityCompanyInfo.telephoneNumber,
      whatsappNumber: data.sanityCompanyInfo.whatsappNumber
    }
  }
  const quickLinks = data?.sanitySiteSettings?.quickLinks

  return (
    <>
      {/* <Seo /> */}
      <Helmet 
        htmlAttributes={{
          lang: 'en-ZA',
        }}
        title={data?.sanitySiteSettings?.title} 
      />
      <Navbar learningMethods={allLearningMethods} studyFields={allStudyFields} location={location} />
      {children}
      <Footer learningMethods={allLearningMethods} studyFields={allStudyFields} socialMediaLinks={socialMediaLinks} quickLinks={quickLinks} contactInfo={contactInfo} />
    </>
  )
}

export default Layout